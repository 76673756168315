import { zoe1, zoe2, zoe3, zoe4, zoe5, zoe6 } from "../images/zoe";
import { erin1, erin2, erin3, erin4, erin5, erin6 } from "../images/erin";
import { erick1, erick2, erick3, erick4, erick5, erick6 } from "../images/erick";
import { matias1, matias2, matias3, matias4, matias5, matias6 } from "../images/matias";
import { garrett1, garrett2, garrett3, garrett4, garrett5, garrett6 } from "../images/garrett";
import { emma1, emma2, emma3, emma4, emma5, emma6 } from "../images/emma";
import { andrew1, andrew2, andrew3, andrew4, andrew5, andrew6, andrew7, andrew8, andrew9, andrew10 } from "../images/andrew";


export const zoe = {
  name: "Zoe de Beurs",
  bio: (
    <div>
      <p>
        I am originally from the Netherlands and am a 3rd year PhD student in
        Planetary Science at MIT. I work on understanding planet-star
        interactions, finding earth-twins using machine learning, and making
        STEM more accessible through outreach and mentoring initiatives. Beyond
        science, I have a lot of energy so I love running, hiking, weight
        lifting, playing ping pong and/or air hockey and have recently gotten
        involved in the MIT rowing club and the Boston Roller Derby League.
      </p>
      <p>
        If I’m not moving or doing research, I am probably eating or making a
        snack. I love to eat and try all kinds of new foods. I spent about 7
        years in Texas so I love making fresh guacamole, tacos, spicy salsa, and
        queso. I also have a soft spot for Flamin’ Hot Cheetos and Takis.
        Besides spicy foods, I also really love making breakfast foods including
        making eggs in all the possible forms (over-easy, poached, scrambled,
        deviled, etc.), experimenting with smoothie recipes, and making pancakes
        and waffles (my favorite breakfast I’ve ever made were blueberry lemon
        ricotta pancakes). My other random interests are playing word-related
        games (wordle, octordle, heardle, absurdle, spelling bee, nytimes
        crossword), reading books (favorite book of all time: My name is Asher
        Lev; favorite author: Chimamanda Ngozi Adiche), binge watching tv
        series, and painting/drawing. I’m pretty outdoorsy and have a car so I'm
        always down for a weekend hiking adventure too 🙂
      </p>
    </div>
  ),
  images: [
    {
      original: zoe2,
    },
    {
      original: zoe3,
    },
    {
      original: zoe4,
    },
    {
      original: zoe5,
    },
    {
      original: zoe6,
    },
  ],
};

export const erick = {
  name: "Erick Gonzalez",
  bio: (
    <div>
      <p>
      Hi everyone! My name's Erick and I'm a member of the class of 2024. I'm from Chicago and I'm majoring in aerospace engineering and minoring in French. I like planes and making stuff and consequently, I'm a part of MIT's Design/Build/Fly team. In my free time, I enjoy going on walks around campus/Boston, playing any sports (my favs are badminton, frisbee, soccer, and volleyball), gaming B) (I love smash), and singing/karaoke! I also like doing a bunch of random things like flinging bottle caps, juggling, petting dogs, bowling, dancing, baking, and more.
      </p>
    </div>
  ),
  images: [
    {
      original: erick1,
    },
    {
      original: erick2,
    },
    {
      original: erick3,
    },
    {
      original: erick4,
    },
    {
      original: erick5,
    },
    {
      original: erick6,
    },
  ],
};

export const erin = {
  name: "Erin Liu",
  bio: (
    <div>
      <p>
        Hi friends! My name is Erin, co ‘25, studying computer science + brain
        and cognitive science at MIT. I’m from Chino Hills, California. On my free time,
        I like to swim, visit the beach, get ramen + boba, go on Hmart runs, walk along the
        Charles, sing karaoke, and bowl. I play flute in the MIT Chamber Ensemble, and I play
        piano and make art for fun. I'm madly obsessed with Studio Ghibli movies, 
        cdramas, and psychological trillers. Recently, I also got into DIY nail painting, and I'm
        always down to do your nails if you come find me in C4 !! 
      </p>
    </div>
  ),
  images: [
    {
      original: erin1,
    },
    {
      original: erin2,
    },
    {
      original: erin3,
    },
    {
      original: erin4,
    },
    {
      original: erin5,
    },
    {
      original: erin6,
    },
  ],
};

export const matias = {
  name: "Matias Vergara",
  bio: (
    <div>
      <p>
      Hallo! I'm Matias, co '26, studying computer science+molecular biology. I come from a bit warmer weather in Southern California, but have really enjoyed the cold of New England! I like to run, go on walks, and sit along the Charles as well as take pictures with my LUMIX G85. Always down to go on a run so if you want to, hit me up 🙂. If you're interested in joining me on the longest run of my life so far this school year, let's do it!
      </p>
      <p>
      I'm looking to expand my knowledge of Boston and Cambridge this year, so if you want company to a museum, historic site, etc let the chat know!! Welcome to C4, it's a blast, get it haha?
      </p>
    </div>
  ),
  images: [
    {
      original: matias1,
    },
    {
      original: matias2,
    },
    {
      original: matias3,
    },
    {
      original: matias4,
    },
    {
      original: matias5,
    },
    {
      original: matias6,
    },
  ],
};

export const garrett = {
  name: "Garrett Blosen",
  bio: (
    <div>
      <p>
      Hey! My name is Garrett and I’m a class of 2025. After spending 4 years on a FIRST robotics team I was inspired to study Mechanical Engineering and Computer Science at MIT (2A-6 Meche/EE Major and 6 CS Minor). I’m originally from San Jose, California but recently moved a little north to the East Bay.
      </p>
      <p>
      At MIT I am part of the Varsity Sailing Team and play cello in the Chamber Music Society. You’ll probably either find me in a makerspace on campus, sailing on the Charles river, or playing smash with fellow floormates in the C4 flounge. In my free time I like to take a break from academics by practicing my old piano rep or hitting the gym, but more importantly I’m really into video games (mainly Rocket League and Valorant). I love nerding out about games and pc’s, going out to grab food, or boba runs any hour of the day so feel free to hmu anytime!
      </p>
    </div>
  ),
  images: [
    {
      original: garrett1,
    },
    {
      original: garrett2,
    },
    {
      original: garrett3,
    },
    {
      original: garrett4,
    },
    {
      original: garrett5,
    },
    {
      original: garrett6,
    },
  ],
};

export const emma = {
  name: "Emma Fu",
  bio: (
    <div>
      <p>
      hello! im emma, co '26, studying computer science and math, and im from socal. in my free time, i love going on walks through boston, baking, or playing games like smash and tetris with people on the floor! I play the piano in the MIT chamber music society, and i also like collecting cute plushies :) as you can see in the photos, i also like travelling, snow, visiting my friends back home, and most importantly, egg tarts. hope you all like c4! 
      </p>
    </div>
  ),
  images: [
    {
      original: emma1,
    },
    {
      original: emma2,
    },
    {
      original: emma3,
    },
    {
      original: emma4,
    },
    {
      original: emma5,
    },
    {
      original: emma6,
    },
  ],
};

export const andrew = {
  name: "Andrew Welter",
  bio: (
    <div>
      <p>
      Hey! My name is Andrew, co’ 25, studying Aerospace Engineering and Economics, minoring in Music. I’m from West Chester, Pennsylvania. In my free time, I love to produce music, lift, play violin, DJ, and build model aircraft. I’m also a huge fan of Disney movies and finding new music on Spotify. I’m part of Design/Build/Fly, Music Production Collaborative, and Dancetroupe. In my time at MIT I was also part of MITSO and the Taekwondo club. I enjoy exploring different parts of Boston, going on random adventures, and am obsessed with airplanes. 
      </p>
    </div>
  ),
  images: [
    {
      original: andrew1,
    },
    {
      original: andrew2,
    },
    {
      original: andrew3,
    },
    {
      original: andrew4,
    },
    {
      original: andrew5,
    },
    {
      original: andrew6,
    },
    {
      original: andrew7,
    },
    {
      original: andrew8,
    },
    {
      original: andrew9,
    },
    {
      original: andrew10,
    },
  ],
};