import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/Home.css";
import "../styles/global.css";
import classNames from "classnames";
import ImageGallery from "react-image-gallery";
import { images } from "../images/c4";

export default function Home() {
  return (
    <div>
      <div className={classNames("home", "content")}>
        <div className="title">
          <h1>Welcome to Conner 4</h1>
        </div>

        <div className="intro">
          <p>
            "Welcome to Conner 4, one of nine floors in{" "}
            <a href="http://web.mit.edu/">MIT</a>&rsquo;s{" "}
            <a href="https://bc.mit.edu/">Burton-Conner House</a> and home of
            the Conner 4 Pirates. We are proud to maintain a diverse and
            supportive culture centered around individual expression, free food,
            and, if the fancy strikes us, a tour of pillaging and plundering
            upon{" "}
            <a href="https://en.wikipedia.org/wiki/Charles_River">
              the high seas
            </a>
            ." - quoted from da old C4
          </p>
        </div>

        <div className="info">
          <h2>Join us for the 2023-2024 school year!</h2>
          <p>
            hi friens! some background about us: Burton Conner underwent renovations between 2019-2022, but ever since Fall 2022, we've been back and kickin!! After we re-opened, C4 has been made up of an all new student body (meaning, no one currently on C4 has been on C4 prior to renovations/covid). so basically, we were served with a clean slate... and it's been SO MUCH FUN building our floor culture!
          </p>
          <div className="images">
            <ImageGallery items={images}></ImageGallery>
          </div>
          <p>
            One of the best parts of being a smol and new living group is that everyone has a direct voice in our floor culture. Wanna watch movies, do puzzles together, pet dogs, dress up in costumes, go out for meals, and cuddle millions of stuffed animals in our cozy lounge? Join our C4 family! :3
          </p>
          <p>
          Currently, we have 22 members (8 co'27, 6 co'26, 9 co'25, 3 co'24). For the 2023-2024 school year, we'll have room for{" "}
            <strong>6 new members!</strong> 🥰{" "}
          </p>
        </div>

        <div>
          <h2>Visit us during CPW!</h2>
          <p>
          Greetings to all prefrosh!
          <strong> This year’s CPW will be held Thursday April 11 to Sunday April 14</strong>. Come by our events to get to know our floor culture, meet our C4 family, and have a blast!
          </p>
          <NavLink to="/events">
          <strong>Here are the CPW events hosted by Conner 4!</strong>
          </NavLink>
          <p>
          Feel free to reach out to the floor team with any questions. Happy CPW!
          </p>
        </div>
      </div>
      <div className="footer">
        <p className="copyright">
          Copyright 2022. Image of Charles River from{" "}
          <a href="https://www.flickr.com/photos/gregdubois/14585498016">
            Greg DuBois
          </a>
        </p>
      </div>
    </div>
  );
}
