import { ghibli, nails, supersmash, pottery } from "../images/events";

export const event1 = {
  name: <h2>C4: Studio Ghibli Movie Night</h2>,
  description: (
    <div>
      <p>(renamed to Wholesome Anime Movie Night b/c copyright)</p>
      <p>When: Thursday April 11 @ 10pm </p>
      <p>Where: Conner 4 main lounge (Burton-Conner floor 4)</p>
      <p>
      Wind down for the day with our favorite anime studio’s movies in our Ghibli-themed C4 flounge!
      </p>
    </div>
  ),
  image: ghibli,
};

export const event2 = {
  name: <h2>C4: Pottery and Paint</h2>,
  description: (
    <div>
      <p>(part of part of BC Bonanza!)</p>
      <p>When: Friday April 12 @ 6:30pm</p>
      <p>Where: Conner 4 main lounge (Burton-Conner floor 4)</p>
      <p>
      Come make your pottery pieces with us, and then paint it too! 
      </p>
    </div>
  ),
  image: pottery,
};

export const event3 = {
  name: <h2>C4: Super Smash Bros. (and other games!)</h2>,
  description: (
    <div>
      <p>When: Friday April 12 @ 8pm</p>
      <p>Where: Conner 4 main lounge (Burton-Conner floor 4)</p>
      <p>
      Come join us in playing games in our flounge! Smash is a common C4 activity, but we also have other options including board games, card games, Tetris, or whatever else you might want to request/bring!
      </p>
    </div>
  ),
  image: supersmash,
};

export const event4 = {
  name: <h2>C4: Nail Salon and Build a Pirate Ship!</h2>,
  description: (
    <div>
      <p>When: Saturday April 13 @ 4pm</p>
      <p>Where: Meeting at the front of BC at the Amherst Alley entrance</p>
      <p>
      Do you want a free nail manicure! We have lots of colors, gels, and C4 nail artists! Get to know us over  painting and snacks. Also, help us build a VERY COOL pirate ship puzzle while you wait! :D     
      </p>
    </div>
  ),
  image: nails,
};