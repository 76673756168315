import React from "react";
import "../styles/global.css";
import "../styles/Person.css";
import ImageGallery from "react-image-gallery";

export default function Person(props) {
  return (
    <div className="person">
      <div className="left">
        <div className="images">
          <ImageGallery items={props.images}></ImageGallery>
        </div>
      </div>
      <div className="right">
        <h2>{props.name}</h2>
        {props.bio}
      </div>
    </div>
  );
}
