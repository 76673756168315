import React from "react";
import "../styles/global.css";
import "../styles/GRA.css";
import classNames from "classnames";
import Person from "../components/Person";
import { zoe } from "../people";

export default function GRA() {
  return (
    <div className={classNames("gra", "content")}>
      <h1>Meet Your GRA</h1>
      <Person {...zoe} />
    </div>
  );
}
