export const images = [
      {
        original: require('./image1.jpg'),
      },
      {
        original: require('./flounge.jpg'),
      },
      {
        original: require('./image2.jpg'),
      },
      {
        original: require('./image3.jpg'),
      },
      {
        original: require('./image4.jpg'),
      },
      {
        original: require('./snorlax.jpg'),
      },
      {
        original: require('./image5.jpg'),
      },
      {
        original: require('./image6.jpg'),
      },
      {
        original: require('./image7.jpg'),
      },
      {
        original: require('./image8.jpg'),
      },
      {
        original: require('./image9.jpg'),
      },
      {
        original: require('./image10.jpg'),
      },
      {
        original: require('./image11.jpg'),
      },
      {
        original: require('./ifaf1.jpg'),
      },
      {
        original: require('./ifaf2.jpg'),
      },
      {
        original: require('./ifaf3.jpg'),
      },
      {
        original: require('./image12.jpg'),
      },
      {
        original: require('./image13.jpg'),
      },
      {
        original: require('./image14.jpg'),
      },
      {
        original: require('./image15.jpg'),
      },
      {
        original: require('./image16.jpg'),
      },
      {
        original: require('./image17.jpg'),
      },
      {
        original: require('./image18.jpg'),
      },
      {
        original: require('./image19.jpg'),
      },
      {
        original: require('./image20.jpg'),
      },
      {
        original: require('./image21.jpg'),
      },
      {
        original: require('./image22.jpg'),
      },
      {
        original: require('./image23.jpg'),
      },
      {
        original: require('./image24.jpg'),
      },
      {
        original: require('./image25.jpg'),
      },
      {
        original: require('./image26.jpg'),
      },
      {
        original: require('./image27.jpg'),
      },
      {
        original: require('./image28.jpg'),
      },
      {
        original: require('./image29.jpg'),
      },
      {
        original: require('./image30.jpg'),
      },
      {
        original: require('./image31.jpg'),
      },
      {
        original: require('./image32.jpg'),
      },
]
