import React from "react";
import "../styles/Home.css";
import "../styles/REX.css";
import classNames from "classnames";
import Event from "../components/Event";
import { event1, event2, event3, event4 } from "../events";

export default function REX() {
  return (
    <div className={classNames("rex", "content")}>
      <h1>CPW 2024 EVENTS!</h1>
      {/* <h1>REX 2023 EVENTS!</h1> */}
      {/* <p>check back later :D</p> */}
      <Event {...event1}/>
      <Event {...event2}/>
      <Event {...event3}/>
      <Event {...event4}/>
    </div>
  );
}
