import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/Navbar.css";
import "../styles/global.css";

export default function Navbar() {
  const [useHamburger, setUseHamburger] = useState(false);
  return (
    <div className="navigation">
      <div className="hamburger-container">
        <button
          className="hamburger"
          onClick={() => {
            setUseHamburger(!useHamburger);
          }}
        >
          {/* icon from heroicons.com */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className={useHamburger ? "navbar-hamburger" : "navbar"}>
        <NavLink className="menuItem" to="/">
          Home
        </NavLink>
        <NavLink className="menuItem" to="/gra">
          Meet Your GRA
        </NavLink>
        <NavLink className="menuItem" to="/floorteam">
          Meet Your Floor Team
        </NavLink>
        <NavLink className="menuItem" to="/events">
          CPW 2024
        </NavLink>
      </div>
    </div>
  );
}
