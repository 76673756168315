import React from "react";
import "../styles/Home.css";
import "../styles/Event.css";

export default function Event(props) {
  return (
    <div className="event">
      <div className="left">
        <img src={props.image}></img>
      </div>
      <div className="right">
        {props.name}
        {props.description}
      </div>
    </div>
  );
}
