import React, { useState } from "react";
import "./styles/global.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import GRA from "./pages/GRA";
import FloorTeam from "./pages/FloorTeam";
import REX from "./pages/REX";

export default function AppFunction() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/gra" element={<GRA />} />
        <Route path="/floorteam" element={<FloorTeam />} />
        <Route path="/events" element={<REX />} />
      </Routes>
    </Router>
  );
}
