import React from "react";
import "../styles/global.css";
import "../styles/FloorTeam.css";
import classNames from "classnames";
import Person from "../components/Person";
import { andrew, emma, erin, erick, matias, garrett } from "../people";

export default function FloorTeam() {
  return (
    <div className={classNames("floorteam", "content")}>
      <h1>Meet Your Floor Team</h1>
      <Person {...andrew} />
      <Person {...emma} />
      <Person {...erin} />
      <Person {...matias} />
      <Person {...erick} />
      <Person {...garrett} />
    </div>
  );
}
